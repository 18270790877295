import { environment } from './environments/environment';

window.onload = () => {
  const main_link = document.createElement('link');
  main_link.type = 'text/css';
  main_link.rel = 'stylesheet';
  main_link.href = 'main_styles.css';
  document.head?.appendChild(main_link);

  const extra_link = document.createElement('link');
  extra_link.type = 'text/css';
  extra_link.rel = 'stylesheet';
  extra_link.href = 'extra_styles.css';
  document.head?.appendChild(extra_link);

  if (environment?.production) {
    import('@angular/core').then((core) => core.enableProdMode());
  }

  import('rxjs').then((operators) => {
    const load$ = operators
      .combineLatest([
        import('./app/app.module'),
        import('@angular/platform-browser-dynamic'),
      ])
      .subscribe(([module, platform]) => {
        platform
          .platformBrowserDynamic()
          .bootstrapModule(module.AppModule)
          .catch((err) => {})
          .finally(() => {
            load$.unsubscribe();
          });
      });
  });
};
